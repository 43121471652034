import NewsFeed from './Components/NewsFeed/index';
function App() {
  return (
    <>  
    <NewsFeed></NewsFeed>
   
  </>
  );
}

export default App;
